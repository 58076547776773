import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { saveLocalStorageCarrinhoTemp, saveLocalStorageIdHotsite, clearLocalStorageIdHotsite, getlocalStorageIdHotsite } from '../../util/LocalStorage'
import { useParams } from "react-router-dom";




export default function PreLogin() {
      const [searchParams] = useSearchParams();
      const navigate = useNavigate();
      const { hotsite } = useParams();

      async function hasHotsite(id = 0) {
            let idHotsiteStorage = getlocalStorageIdHotsite();
            let idHotsite = id;

            if (isNaN(idHotsite) || idHotsite === '0') {
                  if (idHotsiteStorage > 0) {
                        clearLocalStorageIdHotsite();
                        navigate(0)
                  }
            }

            let idHotsiteProps = parseInt(idHotsite);

            if (idHotsite > 0) {
                  if (idHotsiteStorage !== idHotsiteProps) {
                        saveLocalStorageIdHotsite(idHotsiteProps)
                        navigate(0)
                  }
            }
      }

      const configLogin = async () => {
            try {
                  let newArrayParams = [];

                  let response = {};
                  let id = 0;
                  id = hotsite ?? '0'

                  // if (!!hotsite && isNaN(hotsite)) {
                  //       response = await getDadosInstitucionaisHotsite(0, hotsite);
                  //       id = response?.data.id;
                  // } else {
                  //       response = await getDadosInstitucionaisHotsite(hotsite ?? 0);
                  //       id = hotsite ?? '0';
                  // }

                  if (response?.errorMessage)
                        throw new Error(response?.errorMessage);

                  hasHotsite(id);

                  searchParams.forEach((value, key) => {
                        newArrayParams.push({ [key]: value });
                  })

                  let objParams = Object.assign({}, ...newArrayParams);
                  let natureza = { id: objParams?.Nid ?? 0, descricao: objParams?.Ndescricao ?? "" }
                  let formObj = { ...objParams, natureza: { ...natureza } }

                  saveLocalStorageCarrinhoTemp(formObj);

                  if (response?.data?.nomeHotsite?.length > 0)
                        navigate(`/login?${response?.data?.nomeHotsite?.replace(/\s+/g, "_")}`, true);
                  else
                        navigate(`/login`);

            } catch (error) {
                  navigate(`/paginaNaoEncontada`, { state: { message: error?.message ?? "" } });
            }
      }



      useEffect(() => {
            configLogin();
      }, [])

      return;
}